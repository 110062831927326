import React from "react";
import Root from "../components/Root";
import SEO from "../components/seo";

const WhatsNew = () => {
  return (
    <Root>
      <SEO title="Tutorials" />
      <h1>What's New</h1>
    </Root>
  );
};

export default WhatsNew;
